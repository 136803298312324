<template>
  <div class="layoutDefault" style="margin-top: 2rem">
    <div class="grid grid-cols-1 gap-4">
      <div class="flex items-center justify-between z-20">
        <div
          class="flex cursor-pointer rounded-lg py-2"
          style="width: 230px"
          @click="$router.go(-1)"
        >
          <IconArrow
            class="mr-3 mt-1 stroke-current text-habu h-6 w-6 stroke-2 transform rotate-0"
          />
          <span class="text-2xl text-normal">CAF List</span>
        </div>

        <div class="mt-3 inline-flex space-x-3">
          <div class="form-control max-w-246">
            <Treeselect
              class="h-9"
              :multiple="false"
              v-model="selectedStatus"
              :options="listStatus"
              placeholder="Select status"
            />
          </div>
          <input
            type="text"
            v-model="search"
            @change="handleStatusChange"
            placeholder="Search CAF ..."
            class="input input-bordered transition rounded-lg w-full py-2 px-3 h-9 focus:ring-transparent"
          />
        </div>
      </div>

      <div class="hcard relative z-10 p-7" v-if="loading">
        <div class="grid sm:grid-cols-2 gap-4 mt-2 animate-pulse">
          <div class="relative" v-for="b in 4" :key="b">
            <div class="hcard-b17 p-5 h-full">
              <div class="flex-grow">
                <div class="grid-cols-1 mb-7">
                  <div class="h-6 w-32 bg-hline rounded-lg"></div>

                  <div class="h-0.5 w-full my-3 bg-hline rounded-lg"></div>

                  <div class="h-5 w-68 bg-hline rounded-lg"></div>
                  <div class="h-5 w-56 mt-1 bg-hline rounded-lg"></div>
                  <div class="h-5 w-60 mt-1 bg-hline rounded-lg"></div>
                  <div class="h-5 w-28 mt-1 bg-hline rounded-lg"></div>
                  <div class="h-5 w-38 mt-1 bg-hline rounded-lg"></div>
                </div>
              </div>
              <div class="flex justify-between">
                <div class="">
                  <div class="h-7 w-24 bg-hline rounded-lg"></div>
                </div>
                <div class="relative">
                  <div class="h-5 w-28 bg-hline rounded-lg"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="hcard relative z-10 p-7" v-else>
        <div class="grid grid-cols gap-3 mt-2">
          <div class="col-span-2 py-24" v-if="!cafs.length">
            <img :src="NoData" class="mx-auto" />
            <div class="capitalize font-semibold text-5xl text-hblue text-center">
              there is no caf yet!
            </div>
          </div>

          <div class="grid sm:grid-cols-2 gap-4">
            <div
              class="relative bg-white font-Poppins caf-box-shadow hover:bg-hgray pl-7 pr-4 pt-3 pb-2 cursor-pointer"
              v-for="b in cafs"
              :key="b.id"
              @click="handleClick(b)"
            >
              <!--@click="MODAL_CAF_DETAIL(b)" @click="DETAIL_CAF(b.id)"-->
              <BgCaf class="absolute h-5/6 right-2 top-0" />
              <div
                class="absolute left-0 w-3 bottom-0 top-0 bg-hservice rounded-tl-lg rounded-bl-lg"
              ></div>

              <div class="flex items-center sm:justify-between">
                <div class="text-hservice font-medium">
                  {{ b.number }}
                </div>
              </div>
              <div class="text-hgray7 text-sm mt-1">
                {{ b.caf_letter ? b.caf_letter.product_name : '-' }}
              </div>
              <div class="text-hgray7 text-sm">
                {{ b.caf_type }}
              </div>
              <div class="mb-4 mt-4 flex space-x-3 items-center">
                <div
                  class="text-sm"
                  :class="{
                    'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-hred1 rounded-full':
                      b.status === 'Created',
                    'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-hred2 rounded-full':
                      b.status === 'Read',
                    'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-hblue rounded-full':
                      b.status === 'Submitted',
                    'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-horange2 rounded-full':
                      b.status === 'Revision',
                    'inline-block px-2 py-1 text-sm font-semibold leading-none text-black bg-hgray12 rounded-full':
                      b.status === 'Rejected',
                    'inline-block px-2 py-1 text-sm font-semibold leading-none text-white bg-hgray12 rounded-full':
                      b.status === 'Approved'
                  }"
                >
                  {{ b.status }}
                </div>

                <div
                  v-if="b.status == 'Revision'"
                  class="text-13px font-Roboto text-hgray5 line-clamp-1"
                >
                  {{ b.revision }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="flex justify-between space-x-2 mx-auto mt-8 text-center"
          style="max-width: 100px"
        >
          <button
            class="btn font-semibold bg-hteal1 hover:bg-hteal1 rounded-full btn-sm border-0"
            :disabled="currentPage == 1"
            @click="pageDecrease"
          >
            «
          </button>
          <button
            class="btn font-semibold rounded-full btn-sm btn-ghost no-animation hover:border-transparent"
          >
            {{ currentPage }}
          </button>
          <button
            class="btn font-semibold bg-hteal1 hover:bg-hteal1 rounded-full btn-sm border-0"
            :disabled="currentPage >= totalPage"
            @click="pageIncrease"
          >
            »
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/icon-arrow.svg?inline'

import NoData from '@/assets/images/icon-noCertificate.png'
import Treeselect from '@riophae/vue-treeselect'
import BgCaf from '@/assets/icons/bg-caf.svg?inline'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'caf-index',
  components: {
    IconArrow,
    Treeselect,
    BgCaf
  },
  data() {
    return {
      NoData,
      loading: true,
      currentPage: 1,
      totalPage: 1,
      selectedStatus: null,
      search: '',
      listStatus: [
        {
          id: 'Created',
          label: 'Created'
        },
        {
          id: 'Read',
          label: 'Read'
        },
        {
          id: 'Submitted',
          label: 'Submitted'
        },
        {
          id: 'Approved',
          label: 'Approved'
        },
        {
          id: 'Revision',
          label: 'Revision'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      cafs: state => state.instrument.cafs
    })
  },
  watch: {
    '$route.query': {
      deep: true,
      handler() {
        this.getData()
      }
    },
    selectedStatus: function () {
      this.handleStatusChange()
    }
  },
  mounted() {
    //this.restoreFilter()
    this.getData()
  },
  methods: {
    ...mapActions('instrument', ['GET_CAF', 'DETAIL_CAF']),
    ...mapActions('modal', ['MODAL_CAF_DETAIL']),
    restoreFilter() {
      let queries = JSON.parse(JSON.stringify(this.$route.query))

      if (queries.page) {
        this.currentPage = parseInt(queries.page)
      }
      queries.t = new Date().getTime()

      this.$router.replace({ query: queries })
    },
    async getData(queryParams = {}) {
      this.loading = true

      const queries = {
        ...this.$route.query,
        per_page: this.perPage,
        ...queryParams
      }

      const res = await this.GET_CAF(queries)

      if (res) {
        this.totalPage = res.meta.pagination.total_pages || this.currentPage
      }

      setTimeout(() => {
        this.loading = false
      }, 300)
    },

    pageDecrease() {
      if (this.currentPage > 1) {
        this.currentPage -= 1
        this.changePage(this.currentPage)
      }
    },
    pageIncrease() {
      if (this.currentPage < this.totalPage) {
        this.currentPage += 1
        this.changePage(this.currentPage)
      }
    },
    changePage(val) {
      let queries = JSON.parse(JSON.stringify(this.$route.query))
      queries.t = new Date().getTime()
      queries.page = val
      queries.status = this.selectedStatus
      queries.search = this.search

      this.$router.push({ query: queries })
      this.getData()
    },
    handleStatusChange() {
      //const statusQuery = this.selectedStatus.join(',')
      const statusQuery = this.selectedStatus
      const searchQuery = this.search
      this.getData({ status: statusQuery, page: 1, search: searchQuery })
    },
    handleClick(b) {
      if (['Created', 'Read', 'Revision'].includes(b.status)) {
        this.DETAIL_CAF(b.id)
      } else {
        this.MODAL_CAF_DETAIL(b)
      }
    }
  }
}
</script>
